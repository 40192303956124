<template>
  <div class="tooltip relative">
    <span class="pl-px block" @touchstart="show" @mouseenter="show" @mouseleave="hide" @touchend="hide">
      <svg class="h-5 w-5 mr-2 mt-px"><use xlink:href="#info--sprite" /></svg>
    </span>
    <transition name="vertical-xs">
      <div v-if="isOpen">
        <div class="tooltip-body" ref="body">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import '@/assets/svg/icons/info.svg?sprite'

export default {
  props: {
    labels: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    show() {
      this.isOpen = true

      Vue.nextTick()
        .then(() => {
          const $body = this.$refs.body
          if (!$body) return

          const leftX = $body.getBoundingClientRect().left
          if (leftX < 10) {
            let delta = -(leftX - 10)
            $body.style.left = delta + 'px'
            $body.classList.add('shift')
          } else {
            $body.classList.remove('shift')
          }
        })
    },

    hide() {
      this.isOpen = false
    }
  }
}
</script>

<style>
.tooltip {
  @apply relative;
}

.tooltip-body {
  @apply absolute bottom-0 left-0 w-64 px-4 py-3 text-left text-xs bg-white shadow-lg;
  transform: translate(-15%, -2rem);

  &.shift {
    transform: translate(-15%, -3rem);
  }

  &:after {
    @apply block bg-white w-3 h-3 absolute;
    content: '';
    bottom: 0;
    left: 15%;
    transform: translate(40%, 50%) rotate(45deg);
  }

  &.shift:after {
    left: 4.5%;
  }

  & p {
    @apply mb-2;
  }
}
</style>
