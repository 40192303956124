<template>
<div class="px-4 md:px-16 lg:px-32">
  <div class="text-center" v-if="isLoading">
    <span class="spinner" />
  </div>

  <div v-if="withFilter && !isLoading" class="flex mo:flex-col sm:items-start mb-4 sm:mb-8 filter">
    <div class="flex">
      <div class="sm:mr-8 mo:mb-2 sm:w-64 mo:w-full">
        <div class="flex">
          <div class="filter-push flex-grow w-1/2">
            <input
              v-model="groupFilter.automatic"
              :value="true"
              @click="resetTransmissionIf(true)"
              type="radio"
              id="featureAutomatic">
            <label for="featureAutomatic">
              {{ labels.filter['transmission-automatic-short'] }}
            </label>
          </div>
          <div class="filter-push -ml-px flex-grow w-1/2">
            <input
              v-model="groupFilter.automatic"
              :value="false"
              @click="resetTransmissionIf(false)"
              type="radio"
              id="featureManual">
            <label for="featureManual">
              {{ labels.filter['transmission-manual-short'] }}
            </label>
          </div>
        </div>
        <div class="w-full">
          <multiselect class="mb-1"
            v-model="groupFilter.make"
            :placeholder="labels.filter['makes']"
            :options="options.make"
            :option-height="35"
            :multiple="true"
            :searchable="false"
            :hide-selected="true"
            :show-labels="false" />
        </div>
      </div>
      <div class="filter-sep mo:hidden" />
    </div>
    <div class="flex flex-wrap items-start align-top sm:ml-8">
      <div class="filter-push mr-2"
        v-for="classification in options.classification"
        :key="classification">
        <input type="checkbox" v-model="groupFilter.classification" :value="classification" :id="classification">
        <label :for="classification">{{ classification }}</label>
      </div>
    </div>
    <div class="w-32 flex-none mo:text-center mo:h-6 sm:text-right ml-auto mo:mr-auto">
      <transition name="fade">
        <button class="border-b border-red-500 text-red-500 text-xs text-right"
          v-if="filterActive"
          @click="clearFilter">
            {{ labels.filter['reset-selection'] }}
        </button>
      </transition>
    </div>
  </div>

  <div class="text-center" v-if="!isLoading && filteredGroups.length == 0">
    <span class="inline-block text-md py-32">{{ labels.filter['no-results'] }}</span>
  </div>

  <ul v-else class="flex flex-wrap -mx-4">
    <li class="w-full px-4 pb-5 text-gray-600  sm:w-1/2 xl:w-1/3"
      v-for="group in paginatedGroups"
      :key="group.id">
      <GroupTile
        :group="group"
        :selected-vehicle="getSelectedVehicleForGroup(group)"
        :page-context="pageContext"
        :percentage-discount="percentageDiscount"
        :percentage-discount-enabled="percentageDiscountEnabled"
        :percentage-discount-name="percentageDiscountName"
        :with-title="withTitle"
        @start-picking="(selectedPriceGroup) => openVehiclePicker(group, selectedPriceGroup)"
        @clear-selection="clearGroupVehicleSelection(group)"
        @goto-inquiry="gotoInquiry" />
    </li>
  </ul>

  <slot></slot>

  <div class="text-center mt-8 sm:mt-16" v-show="filteredGroups.length > 0 && morePagesAvailable">
    <div class="mb-4">{{ paginatedGroups.length }} / {{ filteredGroups.length }}</div>
    <button @click="showMore()" class="show-more-btn text-gray-500"><ArrowDown class="fill-current" /></button>
  </div>

  <VehiclePicker ref="vehiclePicker" :with-title="withTitle"
    @vehicle-selected="onGroupVehicleSelected" />
</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { getGroups } from '@/services/fleet-service'

import Multiselect from 'vue-multiselect'
import VehiclePicker from './fleet/VehiclePicker'
import GroupTile from './fleet/GroupTile'
import ArrowDown from '@/assets/svg/icons/arrow-h-w.svg?inline'
import tracking from 'hertz-tracking'

const TILES_PER_PAGE = 21

export default {
  components: {
    Multiselect,
    GroupTile,
    ArrowDown,
    VehiclePicker,
  },
  provide() {
    return {
      labels: this.labels,
      modelGuaranteeDescription: this.modelGuaranteeDescription,
    }
  },
  props: {
    pageContext: {
      type: String,
      default: 'home',
    },
    pageId: {
      type: String,
      default: null,
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    withFilter: {
      type: Boolean,
      default: true
    },
    percentageDiscount: {
      type: Number,
      default: 0,
    },
    percentageDiscountEnabled: {
      type: Boolean,
      default: false
    },
    percentageDiscountName: {
      type: String,
      default: 'Rabatt'
    },
    modelGuaranteeDescription: {
      type: String,
      default: null
    },
    withTitle: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      isLoading: true,
      groups: [],
      groupFilter: {
        make: [],
        classification: [],
        automatic: null,
        sortBy: '',
      },
      options: {
        make: [],
        classification: [],
        sortBy: [
          { label: 'sort-reset', property: '', descending: false },
          { label: 'sort-price-ascending', property: 'price', descending: false },
          { label: 'sort-price-descending', property: 'price', descending: true },
        ]
      },
      groupVehicleSelection: {},
      page: 1
    }
  },
  computed: {
    ...mapGetters([
      'isVehicleSelected'
    ]),
    ...mapState([
      'selectedVehicle'
    ]),
    filteredGroups() {
      let selection = this.groups

      // Make
      if (this.groupFilter.make.length) {
        selection = selection.filter(item => {
          return item.make.some(m => {
            return this.groupFilter.make.includes(m)
          })
        })
      }

      // Transmission
      if (this.groupFilter.automatic !== null) {
        const transmission = this.groupFilter.automatic ? 'transmissionAutomatic' : 'transmissionManual'
        selection = selection.filter(group => Array.isArray(group.features) && group.features.some(feature => feature.value === transmission))
      }
      if(this.pageContext === 'best-deal') {
        selection = selection.filter(group => {
          return !!group.priceDiscounted
        })
      }
      //TODO: handle in backend
      // no ef-groups on partner pages
      if(this.pageContext === 'partner') {
        selection = selection.filter(group => {
          return !Array.isArray(group.price)
        })
      }

      // Classification
      if (this.groupFilter.classification.length) {
        selection = selection.filter(item => {
          return item.classification.some(c => {
            return this.groupFilter.classification.includes(c)
          })
        })
      }

      let result = 0
      const sortBy = this.groupFilter.sortBy
      switch (sortBy.property) {
        case 'price':
          selection.sort((a, b) => {
            if (a.price < b.price) {
              result = -1
            }
            if (a.price > b.price) {
              result = 1
            }
            return sortBy.descending ? result *= -1 : result
          })
          break
        default:
          selection.sort((a, b) => {
            if (a.defaultSort < b.defaultSort) {
              return -1
            }
            if (a.defaultSort > b.defaultSort) {
              return 1
            }
            return 0
          })
      }

      // show items with group-discount first
      if(this.pageContext !== 'partner' && this.pageContext !== 'best-deal') {
        selection.sort((a,b) => {
          if(a.priceDiscounted) {
            return (b.priceDiscounted) ? 1 : -1
          }
          return 0
        })
      }

      return selection
    },
    paginatedGroups() {
      return this.filteredGroups.slice(0, this.page * TILES_PER_PAGE)
    },
    morePagesAvailable() {
      return this.page < Math.ceil(this.filteredGroups.length / TILES_PER_PAGE)
    },
    filterActive() {
      return this.groupFilter.make.length > 0
        || this.groupFilter.classification.length > 0
        || this.groupFilter.automatic != null
    },
  },
  created() {
    getGroups(this.pageId).then(groups => {
      this.groups = groups
      this.populateOptions()
      this.isLoading = false
    })
  },
  methods: {
    gotoInquiry(groupOrVehicle = null) {
      if (!groupOrVehicle) {
        return
      }

      let inquiryItem
      if (groupOrVehicle.id === 0) {
        inquiryItem = {
          ...groupOrVehicle,
        }
      } else {
        inquiryItem = groupOrVehicle
      }

      this.$store.commit('selectVehicle', inquiryItem)
      tracking.pushEvent({
        'car_type': inquiryItem.title,
        'eventAction': 'Auswahl',
        'eventCategory': 'Fahrzeug',
        'eventLabel': inquiryItem.title,
        'event': 'event'
      })

      const $a = document.querySelector('a[name="inquiry"]')
      window.scrollTo({ top: $a.offsetTop, behavior: 'smooth' })
    },
    openVehiclePicker(group, selectedPriceGroup) {
      this.$refs.vehiclePicker.open(group, this.getSelectedVehicleForGroup(group), selectedPriceGroup)
    },
    closeVehiclePicker() {
      this.$refs.vehiclePicker.close()
    },
    getSelectedVehicleForGroup(group) {
      if (!this.groupVehicleSelection.hasOwnProperty(group.id)) {
        return null
      }
      return this.groupVehicleSelection[group.id]
    },
    onGroupVehicleSelected({ vehicle, group }) {
      Vue.set(this.groupVehicleSelection, group.id, vehicle)
    },
    clearGroupVehicleSelection(group) {
      Vue.delete(this.groupVehicleSelection, group.id)
    },
    showMore() {
      this.page++
    },
    populateOptions() {
      this.groups.forEach((v, i) => {
        v.defaultSort = i
      })

      let makes = []
      this.groups.forEach(v => makes.push(...v.make))
      this.options.make = makes.filter(function(item, index) {
        return makes.indexOf(item) >= index
      }).sort()

      let classifications = []
      this.groups.forEach(v => classifications.push(...v.classification))
      this.options.classification = classifications.filter(function(item, index) {
        return classifications.indexOf(item) >= index
      }).sort()
    },
    clearFilter() {
      this.groupFilter.classification = []
      this.groupFilter.make = []
      this.groupFilter.automatic = null
    },
    resetTransmissionIf(val) {
      if (val == this.groupFilter.automatic) {
        this.groupFilter.automatic = null
      } else {
        this.groupFilter.automatic = val
      }
    },
    onSortChange() {
      this.$nextTick(() => {
        if (this.groupFilter.sortBy.value === '') {
         this.groupFilter.sortBy = []
        }
      })
    },
    customSortLabel({ label }) {
      return this.labels.filter[label]
    },
  },
  watch: {
    groupFilter: {
      deep: true,
      handler() {
        this.page = 1
      }
    }
  }
}
</script>

<style>

.fleet:before,
.fleet:after {
  @apply h-32 block w-full;
  content: '';
  transform: skewY(-4deg) translate(0, -50%);
  background-color: inherit;
}

@screen mo {
  .fleet:before,
  .fleet:after {
    @apply h-16;
  }
}

.fleet:before {
  @apply mt-32;
}

.fleet:after {
  @apply mb-16;
  transform: skewY(-4deg) translate(0, 50%);
}

.fleet-item {
  @apply text-center bg-white shadow select-none;
  border: 1px solid #cecece;
  transition: all 0.1s ease;
}


.filter {
  @apply text-gray-700 text-sm select-none relative;
}

.filter-sep {
  @apply block w-px mb-1;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAD0lEQVQImWNgYGBoYEAGAAcSAIEdYHRDAAAAAElFTkSuQmCC);
  opacity: 0.8;
}

.filter-push {
  @apply block flex-none mb-1;
  border: 1px solid theme('colors.gray.400');

  & input {
    display: none;
  }

  & label {
    @apply inline-block text-center w-full h-full px-4 py-1 pb-2 cursor-pointer;
    background-color: white;
    transition: all 0.1s;
  }

  & input:checked {
    & + label {
      @apply text-black;
      background-color: #eee;
    }
  }
}
</style>
