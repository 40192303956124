var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"mo:w-full mo:text-center"},[_c('ul',{staticClass:"sm:flex",class:{ 'flex mr-4 md:mr-0' : _vm.isSecondary }},_vm._l((_vm.sanitizedItems),function(item,index){return _c('li',{key:index,staticClass:"mo:py-2 mo:border-gray-500",class:{
        'pl-4 lg:pl-8 mo:border-b': !_vm.isSecondary,
        'mo:border-t': index === 0 && !_vm.isSecondary,
        'md:mr-0': index === _vm.items.length - 1 }},[_c('a',{staticClass:"text-gray-600 text-sm lg:text-md uppercase hover:text-black",class:{
          'text-xs opacity-75 px-2': _vm.isSecondary,
          'text-sm': !_vm.isSecondary,
        },attrs:{"href":item.href},on:{"click":function($event){item.isAnchor ? _vm.goto($event, item.href.substring(1)) : null}}},[_vm._v("\n        "+_vm._s(item.label)+"\n      ")])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }