<template>
  <div>
    <transition name="fade">
      <div v-if="isOpen">
        <div class="fixed w-full h-full inset-0 overflow-scroll z-50" style="background-color:rgba(0,0,0,0.4)">
          <div class="flex flex-row justify-center items-center" :class="{ 'h-full': !hasOversize }">
            <div ref="container" class="
              relative
              w-full
              max-w-6xl px-4 lg:px-16 py-10
              lg:mx-32
              text-gray-600 bg-gray-400 shadow-xl
            ">
              <div v-if="!isLoading">
                <div>
                  <header class="mb-5">
                    <h2>
                      <span v-if="withTitle">{{ labels['fleet-group-label'] }}: </span><strong>{{ group.title }}</strong>
                    </h2>
                  </header>
                  <ul class="flex flex-wrap -mx-2 bg-gray-400 mo:pb-48">
                    <li
                      v-for="(vehicle) in vehicles"
                      :key="vehicle.id"
                      class="w-full md:w-1/2 xl:w-1/3 mb-4 px-2">
                      <div
                        class="fleet-item h-full relative"
                        @click="vehicle.available ? selectVehicle(vehicle) : null">
                        <div
                          class="
                            h-full outline-4 outline
                            transition-all duration-100
                          "
                          :class="{
                            'outline-transparent cursor-pointer hover:outline-gray-100': vehicle.available && vehicle !== selectedVehicle,
                            'outline-hertz-yellow cursor-default bg-white': vehicle.available && vehicle === selectedVehicle,
                          }">
                          <template v-if="vehicle.id == 0">
                            <div class="h-full">
                              <div class="relative">
                                <header class="w-full absolute top-0 left-0 z-10">
                                  <div class="absolute top-0 left-0 flex pt-2 pl-2">
                                    <Tooltip>
                                      <p v-html="vehicle.tooltip" />
                                    </Tooltip>
                                  </div>
                                  <div class="pt-2 pl-8 pr-2 flex flex-grow items-center justify-between">
                                    <h3 class="pl-4 text-md text-left leading-tight text-gray-600 pb-4" v-html="vehicle.title" />
                                  </div>
                                </header>
                              </div>
                              <div
                                :class="{ 'scale-100 opacity-100': selectedVehicle === vehicle }"
                                class="transform scale-95 opacity-90 overflow-hidden aspect-w-3 aspect-h-2 relative mx-2  transition-all duration-300 ease-out">
                                <img
                                  class="absolute w-full inset-0 mt-8   lazyload"
                                  data-sizes="auto"
                                  :data-srcset="vehicle.imageSrcSet"
                                  :data-src="vehicle.imageSrc"
                                  :alt="vehicle.title">
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <header class="relative px-2">
                              <div class="absolute top-0 left-0 pt-2 pl-2">
                                <Tooltip>
                                  <template v-if="vehicle.id == 0">
                                    <p v-html="vehicle.tooltip" />
                                  </template>
                                  <template v-else>
                                    <p><strong>{{ labels['model-guarantee'] }}</strong>: {{ labels['model-guarantee-info'] }}</p>
                                  </template>
                                </Tooltip>
                              </div>
                              <h3
                                :class="{ 'text-black': vehicle === selectedVehicle }"
                                class="text-lg text-center font-bold pt-2 px-10 leading-tight text-gray-600">
                                {{ vehicle.title }}
                              </h3>
                            </header>
                            <div class="px-10">
                              <div
                                :class="{ 'scale-100 opacity-100': selectedVehicle === vehicle }"
                                class="transform scale-95 opacity-90 aspect-w-3 aspect-h-2 relative mx-2  transition-all duration-300 ease-out">
                                <img
                                  class="absolute w-full inset-0    lazyload"
                                  data-sizes="auto"
                                  :data-srcset="vehicle.imageSrcSet"
                                  :data-src="vehicle.imageSrc"
                                  :alt="vehicle.title">
                              </div>
                            </div>
                            <div class="italic text-sm text-gray-600 -mt-2 mb-4">
                              + {{ formattedSurcharge[0] }}.<span><sup>{{ formattedSurcharge[1] }}</sup> CHF</span>
                            </div>
                          </template>
                          <div
                            v-if="!vehicle.available"
                            class="absolute inset-0 flex justify-center items-center"
                            style="background-color:rgba(255, 255, 255, 0.8)">
                            <span class="italic font-bold text-lg uppercase">
                              {{ labels['fleet-model-unavailable'] }}
                            </span>
                          </div>
                        </div>
                      </div>
                   </li>
                  </ul>
                  <div class="md:mt-4 mo:bg-gray-400 mo:fixed bottom-0 left-0 w-full">
                    <div class="inquiry-form__info-panel flex md:mb-10 mt-4 md:mt-0">
                      <div class="w-4 h-4 mr-3 mt-1 flex-none">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.41 78.41"><path d="M26.09 37.11c-.55.66-1.03 1.42-.67 1.95.57.85 2.27-1.04 2.27-1.04 1.45-1.41 2.89-3.15 4.6-4.11.87-.49 2.62-1.13 2.13.6-.76 2.65-6.16 15.35-6.16 15.35s-3.6 7.01-1.71 10.52c.93 1.72 3.26 1.7 4.94 1.48 4.42-.58 8.36-3.1 11.36-6.31 2.65-2.84 3.32-4.26 2.46-4.74-.99-.55-2.42 1.43-3.02 1.97-.17.15-.96.77-1.12 1.01-.83.75-2.08 1.97-3.29 1.86-.96-.09-.99-1.03-.82-1.8.66-3.03 2.84-7.86 2.84-7.86l3.51-10.14s2.65-5.49-1.89-6.44c0 0-3.98-1.04-7.96 1.04-1.38.73-2.62 1.71-3.75 2.78-.52.49-1.02 1.01-1.5 1.54-.4.42-1.86 1.9-2.22 2.34z" fill="currentColor" /><ellipse transform="rotate(-5.601 42.83 21.335)" cx="42.85" cy="21.34" rx="5.49" ry="5.12" fill="currentColor" /><circle cx="39.22" cy="39.03" r="32.9" fill="none" stroke="currentColor" stroke-width="3" stroke-miterlimit="10" /></svg>
                      </div>
                      <span v-html="this.modelGuaranteeDescription" />
                    </div>
                    <div class="text-xl font-bold text-center">
                      <div :class="{ 'strikethru text-lg': hasDiscount }">
                        CHF {{ formattedPrice[0] }}.<span><sup class="text-xs">{{ formattedPrice[1] }}</sup> / {{ labels['month'] }}</span>
                      </div>
                      <div v-if="hasDiscount" class="mo:mt-4">
                        CHF {{ formattedDiscountedPrice[0] }}.<span><sup class="text-xs">{{ formattedDiscountedPrice[1] }}</sup> / {{ labels['month'] }}</span>
                      </div>
                    </div>
                    <div class="h-6 mt-2">
                      <div v-if="selectedVehicle && selectedVehicle.id !=0" class="text-center italic underline text-sm text-gray-600">
                        {{ surchargeNote }}
                      </div>
                    </div>
                    <div class="flex mo:p-4 justify-center items-center pt-4">
                      <div class="mo:hidden w-1/3" />
                      <div class="mo:w-1/2 w-1/3 flex justify-center">
                        <button
                          class="button"
                          :disabled="selectedVehicle === null"
                          @click="commitSelection">
                          {{ labels['fleet-commit-selection'] }}
                        </button>
                      </div>
                      <div class="mo:w-1/2 w-1/3 flex justify-center">
                        <button
                          @click="close"
                          class="uppercase text-sm  tracking-wide border-b border-current mb-px opacity-7">
                          {{ labels['cancel'] }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="absolute top-0 right-0 mt-4 mr-4">
                  <button @click="close">&#x274C;</button>
                </div>
              </div>

              <div v-if="isLoading">
                <div class="text-center" v-if="isLoading">
                  <span class="spinner" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getVehiclesForGroup } from '@/services/fleet-service'
import groupVehiclesCache from '@/util/group-vehicles-cache'
import Tooltip from './Tooltip'
import Vue from 'vue'

export default {
  inject: ['labels', 'modelGuaranteeDescription'],
  components: {
    Tooltip
  },
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hasOversize: false,
      isOpen: false,
      isLoading: true,
      selectedVehicle: null,
      selectedPriceGroup: null,
      formattedPrice: 0,
      formattedDiscountedPrice: 0,
      group: {},
    }
  },
  computed: {
    hasDiscount() {
      return this.selectedPriceGroup.discounted !== undefined && this.selectedPriceGroup.discounted > 0
    },
    formattedSurcharge() {
      return this.formatPrice(this.group.modelGuaranteeSurcharge)
    },
    vehicles() {
      if (this.isLoading || this.group === null || !groupVehiclesCache.get(this.group.id)) {
        return false
      }
      const sv = {
        id: 0,
        available: true,
        title: `${this.labels['fleet-model-surprise']} <strong>${this.labels['fleet-model-surprise-label']}</strong>`,
        tooltip: `<strong>${this.labels['fleet-model-surprise-label']}</strong>: ${this.labels['fleet-model-surprise-info']}`,
        hertzId: this.group.hertzId,
        imageSrcSet: this.group.imageSrcSet,
      }
      return [sv, ...groupVehiclesCache.get(this.group.id)]
    },
    surchargeNote() {
      const l = this.labels['fleet-model-guarantee-surcharge-note']
      return l.replace('{n}', this.group.modelGuaranteeSurcharge)
    },
  },
  methods: {
    updatePrice() {
      this.formattedPrice = this.formatPrice(this.selectedPriceGroup.price)
      this.formattedDiscountedPrice = this.hasDiscount ? this.formatPrice(this.selectedPriceGroup.discounted) : 0
    },
    formatPrice(price) {
      return price.toFixed(2).toString().split('.')
    },
    open(group, selectedVehicle = null, selectedPriceGroup) {
      this.isOpen = true
      this.group = group
      this.selectedPriceGroup = selectedPriceGroup
      this.selectedVehicle = selectedVehicle
      document.body.classList.add('md:overflow-hidden')

      if (!groupVehiclesCache.get(this.group.id)) {
        this.isLoading = true
        getVehiclesForGroup(this.group.id)
          .then(vehicles => {
            groupVehiclesCache.set(this.group.id, vehicles)
            this.isLoading = false
            Vue.nextTick(() => {
              this.hasOversize =  this.$refs.container.getBoundingClientRect().height > window.innerHeight
            })

          })
      }
      this.updatePrice()
    },
    close() {
      this.isOpen = false
      document.body.classList.remove('md:overflow-hidden')
    },
    selectVehicle(vehicle) {
      this.selectedVehicle = vehicle
      this.selectedVehicle.mileage = this.selectedPriceGroup
    },
    commitSelection() {
      this.$emit('vehicle-selected', {
        vehicle: this.selectedVehicle,
        group: this.group
      })
      this.close()
    }
  }
}
</script>
<style>
.tl-1\/2 {
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
}
</style>
