import axios from 'axios'

const lang = document.documentElement.getAttribute('lang').split('-')[0]
const apiLangPrefix = lang === 'de' ? '' : '/' + lang

const API_URL_GROUPS = `${apiLangPrefix}/api/fleet/index.json`
const API_URL_GROUPS_FOR_PAGE = id => `${apiLangPrefix}/api/fleet/index/${id}.json`
const API_URL_VEHICLES_FOR_GROUP = id => `/api/fleet/${id}.json`

const getGroups = function(pageId = null) {
  const url = pageId ? API_URL_GROUPS_FOR_PAGE(pageId) : API_URL_GROUPS
  return axios.get(url)
    .then(result => {
      if (result.data && result.data.groups) {
        return result.data.groups
      } else {
        throw new Error('Invalid API response!')
      }
    })
    .catch(error => {
      console.log('Error:', error)
    })
}

const getVehiclesForGroup = function($groupId) {
  return axios.get(API_URL_VEHICLES_FOR_GROUP($groupId))
    .then(result => {
      if (result.data && result.data.vehicles) {
        return result.data.vehicles
      } else {
        throw new Error('Invalid API response!')
      }
    })
    .catch(error => {
      console.log('Error:', error)
    })}

export { getGroups, getVehiclesForGroup }
