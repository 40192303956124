<template>
  <article class="fleet-item">
    <header class="relative p-2 mb-4">
      <div class="absolute top-0 left-0 pl-3 pt-3">
        <Tooltip>
          <p>
            <template v-if="selectedVehicle && selectedVehicle.id !== 0 || isVehicleOnly">
              <strong>{{ labels['model-guarantee'] }}</strong>: {{ labels['model-guarantee-info'] }}
            </template>
            <template v-else>
              <strong>{{ labels['fleet-group'] }}</strong>: {{ labels['fleet-group-info'] }}
            </template>
          </p>
        </Tooltip>
      </div>
      <div class="pt-2 mx-auto text-gray-600">
        <h2 v-if="withTitle" class="text-sm">
          {{ selectedVehicle && selectedVehicle.id !== 0 || isVehicleOnly ? labels['model-guarantee'] : labels['fleet-group-label'] }}
        </h2>
        <h3 class="text-lg text-center font-bold px-10 leading-tight" v-html="groupOrVehicle.label" />
      </div>
      <div v-if="isFullElectro" class="absolute top-0 right-0 mt-2 mr-4 sm:mt-0 sm:mr-3 xl:mt-2 xl:mr-4">
        <svg class="w-12 h-12 sm:w-10 sm:w-10 lg:w-16 lg:h-16 xl:w-12 xl:h-12">
          <use xlink:href="#full-electro-badge--sprite" />
        </svg>
      </div>
    </header>
    <div>
      <div class="px-16">
        <div class="aspect-w-3 aspect-h-2 relative">
          <transition name="quickfade">
            <div>
              <img
                class="absolute w-full inset-0 lazyload"
                v-if="groupOrVehicle.imageSrcSet"
                data-sizes="auto"
                :data-srcset="groupOrVehicle.imageSrcSet"
                :data-src="groupOrVehicle.imageSrc"
                :alt="groupOrVehicle.label"
                :key="groupOrVehicle.id">
              <div
                v-if="isSoldOut"
                class="absolute inset-0 flex justify-center items-center"
                style="background-color:rgba(255, 255, 255, 0.85)">
                <span class="italic font-bold text-lg uppercase">
                  {{ labels['fleet-model-unavailable'] }}
                </span>
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div class="px-4 mt-4 mb-6  text-sm">
        <template v-if="!isVehicleOnly">
          <template v-if="!selectedVehicle">
            <p class="mb-1">{{ labels['fleet-you-want-specific-model'] }}</p>
          </template>
          <div class="flex flex-wrap justify-around">
            <button
              class="block text-blue-500 mx-2 uppercase font-bold border-b border-current mb-2"
              @click="$emit('start-picking', selectedPriceGroup )">
              {{ selectedVehicle ? labels['fleet-change-model'] : labels['fleet-choose-model'] }}
            </button>
            <button
              v-if="selectedVehicle"
              @click="$emit('clear-selection')"
              class="block uppercase font-bold border-b border-current text-gray-400 mb-2">
              {{ labels['fleet-clear-selection'] }}
            </button>
          </div>
        </template>
      </div>
      <div class="px-4 lg:px-5 pb-4 -mt-2">
        <div class="lg:px-5 mb-5">
          <hr class="h-1 bg-dashed">
          <ul class="flex flex-wrap text-left px-1 text-sm leading-tight">
            <li class="w-1/2 mb-4 flex px-1" v-for="attribute in attributes.slice(0,6)" :key="attribute.id">
              <svg class="h-4 w-4 mr-2 mt-px flex-none"><use :xlink:href="`#${attribute.id}--sprite`" /></svg>
              <span v-html="attribute.value"></span>
            </li>
          </ul>
          <SlideUpDown :active="isOpen" :duration="300">
            <ul class="flex flex-wrap text-left px-1 text-sm leading-tight">
              <li class="w-1/2 mb-4 flex px-1" v-for="attribute in attributes.slice(6)" :key="attribute.id">
                <svg class="h-4 w-4 mr-2 mt-px flex-none"><use :xlink:href="`#${attribute.id}--sprite`" /></svg>
                <span v-html="attribute.value"></span>
              </li>
            </ul>
          </SlideUpDown>
          <div v-if="attributes.length > 6" class="text-center">
            <button @click="isOpen = !isOpen" :class="['details-button', { 'is-open': isOpen }]">
              <svg><use xlink:href="#arrow-h-w--sprite" /></svg>
            </button>
          </div>

          <hr class="h-1 bg-dashed">

          <div class="mt-6 mb-4 text-sm px-2">
            <p class="mb-1" v-html="labels['fleet-group-mileage-info']"></p>
            <v-select
              class="v-select"
              :value="selectedMileage"
              :options="mileagePrices.shortTerm"
              label="distance"
              :searchable="false"
              @input="setMileage" />
          </div>
          <div class="mb-8 text-sm px-2">
            <p class="mb-1" v-html="labels['fleet-group-term-info']"></p>
            <v-select
              class="v-select"
              :value="selectedTerm"
              :options="termOptions"
              label="label"
              :searchable="false"
              @input="setGroupTerm" />
          </div>

          <div class="leading-relaxed text-real-black">
            <template v-if="!this.price">
              <p class="text-xl font-bold">Auf Anfrage</p>
            </template>
            <template v-else>
              <div v-if="formattedStrikethroughPrice" class="text-xl font-bold">
                <s class="strikethru text-lg">
                  CHF {{ formattedStrikethroughPrice[0] }}.<sup class="text-xs">{{ formattedStrikethroughPrice[1] }}</sup><span class="text-sm"> / {{ labels['month'] }}</span>
                </s>
              </div>

              <div class="text-xl font-bold">
                CHF {{ formattedPrice[0] }}.<span><sup class="text-xs">{{ formattedPrice[1] }}</sup> / {{ labels['month'] }}</span>
              </div>
              <div v-if="selectedVehicle && selectedVehicle.id !== 0" class="mt-1">
                <p class="italic text-sm text-gray-600">
                  {{ surchargeNote }}
                </p>
              </div>
            </template>

            <template v-if="discountType == 'percentage'">
              <div class="flex items-center justify-center w-full pt-3">
                <div class="text-lg font-bold pr-3">
                  {{ percentageDiscountName }}
                </div>
                <div class="flex-none w-32 h-32 rounded-full bg-black shadow-xl text-white flex flex-col items-center justify-center lg:-mr-6" style="transform: rotate(5deg);">
                  <div class="font-bold text-hertz-yellow text-xl md:text-2xl ">-{{ percentageDiscount }}%</div>
                </div>
              </div>
            </template>

            <template v-if="discountType == 'override'">
              <div class="flex items-center justify-center w-full pt-3 leading-tight">
                <div class="text-sm pr-3">
                  <div class="font-bold md:text-base pb-1">{{ discountCopy.priceDiscountedInfo }}</div>
                  <div class="text-xs md:text-sm">{{ discountCopy.priceDiscountedInfo2 }}</div>
                </div>
                <div class="flex-none w-32 h-32 rounded-full bg-black shadow-xl text-white flex flex-col items-center justify-center lg:-mr-6" style="transform: rotate(5deg);">
                  <div class="font-bold text-hertz-yellow text-xl md:text-2xl ">-{{ discountInPercent }}%</div>
                  <div class="text-xs md:text-sm px-2 pt-1">{{ discountCopy.priceDiscountedDuration }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>

        <div class="group relative">
          <a href="#inquiry"
            class="button button--full"
            @click.prevent="select">
            {{ labels['make-inquiry'] }}
          </a>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import { paramCase } from 'param-case'
import Tooltip from './Tooltip'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import '@/assets/svg/icons/all-season.svg?sprite'
import '@/assets/svg/icons/date.svg?sprite'
import '@/assets/svg/icons/complete-coverage.svg?sprite'
import '@/assets/svg/icons/deductible.svg?sprite'
import '@/assets/svg/icons/maintenance.svg?sprite'
import '@/assets/svg/icons/mileage.svg?sprite'
import '@/assets/svg/icons/motorway-sticker.svg?sprite'
import '@/assets/svg/icons/taxes.svg?sprite'
import '@/assets/svg/icons/transmission-automatic.svg?sprite'
import '@/assets/svg/icons/transmission-manual.svg?sprite'
import '@/assets/svg/icons/no-deposit.svg?sprite'
import '@/assets/svg/icons/arrow-h-w.svg?sprite'
import '@/assets/svg/icons/hybrid.svg?sprite'
import '@/assets/svg/icons/plug-in.svg?sprite'
import '@/assets/svg/icons/full-electro.svg?sprite'
import '@/assets/svg/icons/ef-processing.svg?sprite'
import '@/assets/svg/icons/full-electro-badge.svg?sprite'

import OpenIndicator from './OpenIndicator'

import {
  getDiscountInPercentage,
  calculatePriceFromPercentageDiscount,
} from '@/util/price-calculator'

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  inject: ['labels'],
  props: {
    group: {
      type: Object,
      default() {
        return {
          id: 0,
          title: 'A group',
          modelGuaranteeSurcharge: 100.00,
        }
      }
    },
    selectedVehicle: {
      type: Object,
      default() {
        return {}
      },
    },
    pageContext: {
      type: String,
      default: 'normal'
    },
    labels: {
      type: Object,
      default() {
        return {}
      }
    },
    canAddMore: {
      type: Boolean,
      default: true
    },
    percentageDiscount: {
      type: Number,
      default: 0
    },
    percentageDiscountEnabled: {
      type: Boolean,
      default: false
    },
    percentageDiscountName: {
      type: String,
      default: 'Rabatt'
    },
    withTitle: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    'v-select': vSelect,
    Tooltip,
  },
  data() {
    return {
      isOpen: false,
      selectedTerm: 0,
      selectedMileage: 0
    }
  },
  beforeMount() {
    this.setMileage(this.mileagePrices.shortTerm[0])
    this.setGroupTerm(this.termOptions[this.termOptions.length-1])
  },
  computed: {
    selectedPriceGroup() {
      const priceGroup = this.group.priceGroups[this.selectedTerm.value][this.selectedMileage.id]
      return priceGroup
    },
    termOptions() {
      let items = []
      const keys = Object.keys(this.group.priceGroups)
      keys.forEach((item) => {
        items.push({label: this.labels[`fleet-mileage-${item.toLowerCase()}`], value: item})
      })

      return items
    },
    isSoldOut() {
      return this.group.isSoldOut
    },
    isVehicleOnly() {
      return this.group.vehicleOnly.hasOwnProperty('id')
    },
    isFullElectro() {
      return this.attributes.some(a => a.id === 'full-electro')
    },
    groupOrVehicle() {
      if(this.isVehicleOnly) {
        return this.group.vehicleOnly
      }
      return this.selectedVehicle ? this.selectedVehicle : this.group
    },
    discountType() {
      const priceGroup = this.selectedPriceGroup
      if (this.percentageDiscountEnabled && this.percentageDiscount > 0) {
        if (priceGroup.discounted > 0 && priceGroup.discounted < priceGroup.price) {
          const overrideInPercent = Math.round(priceGroup.discounted / priceGroup.price * 100)
          if (this.percentageDiscount < overrideInPercent) {
            return 'override'
          }
        }

        return 'percentage'
      }
      if (priceGroup.discounted > 0 && priceGroup.discounted < priceGroup.price) {
        return 'override'
      }

      return false
    },
    discountCopy() {
      return {
        priceDiscountedInfo: this.group.priceDiscountedInfo,
        priceDiscountedInfo2: this.group.priceDiscountedInfo2,
        priceDiscountedDuration: this.group.priceDiscountedDuration,
      }
    },
    price() {
      switch (this.discountType) {
        case 'percentage':
          return calculatePriceFromPercentageDiscount(this.selectedPriceGroup.price, this.percentageDiscount)
        case 'override':
          return Math.min(this.selectedPriceGroup.price, this.selectedPriceGroup.discounted)
        default:
          return this.selectedPriceGroup.price
      }
    },
    strikethroughPrice() {
      return this.discountType
        ? this.selectedPriceGroup.price
        : false
    },
    discountInPercent() {
      return this.discountType == 'override'
        ? getDiscountInPercentage(this.selectedPriceGroup.price, this.selectedPriceGroup.discounted)
        : false
    },
    formattedPrice() {
      return this.price
        ? this.formatPrice(this.price)
        : false
    },
    formattedStrikethroughPrice() {
      return this.strikethroughPrice
        ? this.formatPrice(this.strikethroughPrice)
        : false
    },
    surchargeNote() {
      const l = this.labels['fleet-model-guarantee-surcharge-note']
      return l.replace('{n}', this.group.modelGuaranteeSurcharge)
    },
    attributes() {
      const attributeSet = []

      attributeSet.push({
        id: 'deductible',
        value: `CHF ${this.group.deductible} ${this.labels.attributes['deductible']}`
      })

      for (let i = 0; i < this.group.features.length; i++) {
        const feature = this.group.features[i]
        const featureId = paramCase(feature.value)
        attributeSet.push({ id: featureId, value: this.labels.attributes[featureId] })
      }

      const electroIndex = attributeSet.findIndex(a => a.id === 'full-electro')
      if (electroIndex !== -1) {
        attributeSet.unshift(attributeSet.splice(electroIndex, 1)[0])
      }

      return attributeSet
    },
    mileagePrices() {
      if (!Array.isArray(this.group.priceGroups)) {
        return this.group.priceGroups
      }
      return false
    },
  },
  methods: {
    setMileage(value) {
      this.selectedMileage = value || this.selectedMileage
      this.groupOrVehicle.mileage = this.selectedMileage
    },
    select() {
      this.groupOrVehicle.title = `${this.groupOrVehicle.label} (${this.selectedMileage.distance})`
      this.$emit('goto-inquiry', this.groupOrVehicle)
    },
    setGroupTerm(value) {
      this.selectedTerm = value || this.selectedTerm.value
      switch(this.selectedTerm.value) {
        case 'shortTerm':
          this.group.term = 30
          break
        case 'midTerm':
          this.group.term = 90
          break
        case 'longTerm':
          this.group.term = 180
          break
      }
      this.groupOrVehicle.termPrice = this.price
      if(this.selectedVehicle) {
        this.selectedVehicle.term = this.group.term
      } else if(this.isVehicleOnly) {
        this.groupOrVehicle.term = this.group.term
      }
    },
    formatPrice(price) {
      return price.toFixed(2).toString().split('.')
    }
  }

}
</script>
<style>
.v-select {
  & .vs__selected  {
    @apply uppercase font-bold mx-0 px-0 justify-center;
    width: 99%;
  }
  & .vs__dropdown-toggle {
    @apply border-t-0 border-l-0 border-r-0 border-blue-500 rounded-none;
  }
  & .vs__dropdown-menu {
    @apply border-current rounded-none;
    & > li {
      @apply uppercase;
    }
  }
  & .vs__dropdown-option {
    @apply text-center flex-wrap;
  }
  & .vs__dropdown-option--highlight {
    @apply bg-blue-500;
    color: white !important;
  }
  & .vs__search {
    @apply flex-grow-0 mx-0 px-0;
  }
  & .vs__selected-options {
    @apply justify-center;
  }
  & .vs__clear {
    @apply hidden;
  }

  & .vs__open-indicator {
    fill: #003899 !important;
  }
}

</style>

<style scoped>
.details-button {
  @apply bg-blue-500 p-2 rounded-full relative cursor-pointer;

  & > svg {
    @apply w-3 h-3 inset-0;
    transform: translate3d(0, 1px, 0) rotate(90deg);
    transition: transform 0.3s ease;
  }

  &.is-open > svg {
    transform: translate3d(0, 0, 0) rotate(-90deg);
  }
}
</style>
