const calculatePriceFromPercentageDiscount = function(price, discount) {
  return Math.round(100 - discount) / 100 * price
}

const getDiscountInPercentage = function(price, priceDiscounted) {
  return 100 - Math.round((100 / price) * priceDiscounted)
}

export {
  getDiscountInPercentage,
  calculatePriceFromPercentageDiscount
}
