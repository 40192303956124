import './polyfills'
import 'lazysizes'
import Vue from 'vue'
import {BadgerAccordion, BadgerAccordionItem} from 'vue-badger-accordion'
import SlideUpDown from 'vue-slide-up-down'
import DeviceMixin from './mixins/device'
import Inquiry from 'hertz-inquiry'
import SwiperSlider from './components/SwiperSlider'
import LanguageSwitcher from './components/LanguageSwitcher'
import BurgerButton from './components/BurgerButton'
import Fleet from './components/Fleet'
import PageNavigation from './components/PageNavigation'

import './style/main.css'
import store from './store'

const CookieNotice = () => import(/* webpackChunkName: "cookie-notice" */ './components/CookieNotice')
const Expander = () => import(/* webpackChunkName: "expander" */ './components/Expander')

// eslint-disable-next-line no-unused-vars
Vue.config.productionTip = false

Vue.mixin(DeviceMixin)

Vue.component('BadgerAccordion', BadgerAccordion)
Vue.component('BadgerAccordionItem', BadgerAccordionItem)
Vue.component('SwiperSlider', SwiperSlider)
Vue.component('SlideUpDown', SlideUpDown)
Vue.component('LanguageSwitcher', LanguageSwitcher)
Vue.component('BurgerButton', BurgerButton)
Vue.component('Fleet', Fleet)
Vue.component('Inquiry', Inquiry)
Vue.component('PageNavigation', PageNavigation)
Vue.component('CookieNotice', CookieNotice)
Vue.component('Expander', Expander)

new Vue({
  el: '#hml-root',
  store,
  delimiters: ['${', '}']
})

setTimeout(() => {
  document.querySelector('html').classList.add('loaded')
}, 300)


